import React from "react"
import { Helmet } from "react-helmet"
import {
  Section,
  SectionHeading,
  SectionLedeDiv
} from "../../../styledComponents/section"
import { ContactSection } from "../../../styledComponents/contact"
import LayoutNoStripe from "../../../components/layoutNoStripe"
import GroupHealingForm from "../../../components/formGroupHealingPainAlignment"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const GroupHealing = ({ data, location }) => {
  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
    <LayoutNoStripe displayNavBorder location={location}>
      <Section>
        <Heading level={1}>
        Group Healing Form
        </Heading>
        <SectionLedeDiv>
          <p>Please fill in this form if you would like healing for a significant pain that is caused by a structural misalignment.</p>
        </SectionLedeDiv>
        <ContactSection>
          <GroupHealingForm />
        </ContactSection>
      </Section>
    </LayoutNoStripe>
    </>
  )
}

export default GroupHealing
