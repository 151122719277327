import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"
import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  StyledSelectWrapper,
  StyledSelect,
  StyledOption,
  ErrorMessage
} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"
import addToMailchimp from 'gatsby-plugin-mailchimp'

const GroupHealingForm = () => {
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)
  const [isKarinaChecked, setIsKarinaChecked] = useState(true)
  const [isNewsletterChecked, setIsNewsletterChecked] = useState(false)


  const interests = [
    // 07010ff6a3
    // curl -H "Authorization: apikey cedd0ed6f90a7c8205e334f8a9218411-us1" https://us1.api.mailchimp.com/3.0/lists/07010ff6a3/interest-categories/2e1178ad10/interests
    // 2e1178ad10 - Audio Purchase 
    {
      "id": "cb4f15c6f6",
      "name": "Newsletter Sign Up"
    }
  ]
  // Validation
  const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required"
    }
    if (!values.lastName) {
      errors.lastName = "Required"
    }

    if (!values.misalignedArea) {
      errors.misalignedArea = "Required"
    }
    
    if (!values.howLongHadPain) {
      errors.howLongHadPain = "Required"
    }
    if (!values.onAnyMedication) {
      errors.onAnyMedication = "Required"
    }
    if (!values.painLevel) {
      errors.painLevel = "Required"
    }

    if (!values.email) {
      errors.email = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address"
    }


    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.karinagrant.co.uk/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `qR9zizJq^qKbv2#5nopD8ly#`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      misalignedArea: "",
      painLevel: "",
      howLongHadPain: "",
      onAnyMedication: ""
    },
    validate,
    onSubmit: (
      {
        firstName,
        lastName,
        email,
        misalignedArea,
        painLevel,
        howLongHadPain,
        onAnyMedication,
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)


      if(isNewsletterChecked){
        axios.post("/.netlify/functions/formHandler" , {
          firstName: values.firstName,
          lastName: values.lastName, 
          emailAddress: values.email, 
          painLevel: values.painLevel,
          kgNewsletter: values.kgNewsletter, 
          painWhere: values.misalignedArea,
          medication: values.onAnyMedication,
          painhowlon: values.howLongHadPain,
          interest: interests[0], 
        })
      } else {
        axios.post("/.netlify/functions/formHandler" , {
          firstName: values.firstName,
          lastName: values.lastName, 
          emailAddress: values.email, 
          painLevel: values.painLevel,
          kgNewsletter: values.kgNewsletter, 
          painWhere: values.misalignedArea,
          medication: values.onAnyMedication,
          painhowlon: values.howLongHadPain,
        })
      }
      // if(isNewsletterChecked){
      //   console.log('newsletter checked', interests[0])
      //   addToMailchimp(values.email, {
      //     firstName: values.firstName,
      //     lastName: values.lastName,
      //     painLevel: values.painLevel,
      //     kgNewsletter: values.kgNewsletter, 
      //     painWhere: values.misalignedArea,
      //     medication: values.onAnyMedication,
      //     painhowlon: values.howLongHadPain,
      //     interest: interests[0]
      //   })
      // } else {
      //   console.log('newsletter NOT checked')
      //   addToMailchimp(values.email, {
      //     firstName: values.firstName,
      //     lastName: values.lastName,
      //     painLevel: values.painLevel,
      //     kgNewsletter: values.kgNewsletter, 
      //     painWhere: values.misalignedArea,
      //     medication: values.onAnyMedication,
      //     painhowlon: values.howLongHadPain
      //   })
      // }

      
      const bodyFormData = new FormData()
      bodyFormData.set("firstName", firstName)
      bodyFormData.set("lastName", lastName)
      bodyFormData.set("email", email)
      bodyFormData.set("misalignedArea", misalignedArea)
      bodyFormData.set("painLevel", painLevel)
      bodyFormData.set("howLongHadPain", howLongHadPain)
      bodyFormData.set("onAnyMedication", onAnyMedication)
      bodyFormData.set("addToNewsletter", isNewsletterChecked)
      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.karinagrant.co.uk/wp-json/contact-form-7/v1/contact-forms/11058/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
          navigate("/bookings/distance-healing/group-thank-you")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFieldset>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="firstName">
            <StyledLabelHeader
              required
              isEmpty={errors.firstName && touched.firstName}
            >
              First Name
            </StyledLabelHeader>
            <StyledInput
              id="firstName"
              name="firstName"
              type="text"
              onChange={handleChange}
              value={values.firstName}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.firstName && touched.firstName ? (
            <span className="error-message">{errors.firstName}</span>
          ) : null}
        </StyledFormElementWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="lastName">
            <StyledLabelHeader
              required
              isEmpty={errors.lastName && touched.lastName}
            >
              Last Name
            </StyledLabelHeader>
            <StyledInput
              id="lastName"
              name="lastName"
              type="text"
              onChange={handleChange}
              value={values.lastName}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.lastName && touched.lastName ? (
            <span className="error-message">{errors.lastName}</span>
          ) : null}
        </StyledFormElementWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="email">
            <StyledLabelHeader
              required
              isEmpty={errors.email && touched.email}
            >
              Email Address
            </StyledLabelHeader>
            <StyledInput
              id="email"
              name="email"
              type="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur("email")}
              required
            />
          </StyledLabel>
          {errors.email && touched.email ? (
            <span className="error-message">{errors.email}</span>
          ) : null}
        </StyledFormElementWrapper>
        
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="misalignedArea">
            <StyledLabelHeader
              required
              isEmpty={errors.misalignedArea && touched.misalignedArea}
            >
              Area of the body that is structurally misaligned:
            </StyledLabelHeader>
            <StyledInput
              id="misalignedArea"
              name="misalignedArea"
              type="text"
              onChange={handleChange}
              value={values.misalignedArea}
              onBlur={handleBlur}
              placeholder="E.g Hip, Shoulder"
              required
            />
          </StyledLabel>
          {errors.misalignedArea && touched.misalignedArea ? (
            <span className="error-message">{errors.misalignedArea}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledSelectWrapper>
          <StyledFormElementWrapper>
            <StyledLabel htmlFor="painLevel">
              <StyledLabelHeader
                required
                isEmpty={errors.painLevel && touched.painLevel}
              >Pain number out of 10? (10 being highest)</StyledLabelHeader>
              <StyledSelect
                defaultValue="default"
                id="painLevel"
                name="painLevel"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <StyledOption value="default" disabled hidden>Please select</StyledOption>
                <StyledOption value="10">10</StyledOption>
                <StyledOption value="9">9</StyledOption>
                <StyledOption value="8">8</StyledOption>
                <StyledOption value="7">7</StyledOption>
                <StyledOption value="6">6</StyledOption>
                <StyledOption value="5">5</StyledOption>
                <StyledOption value="4">4</StyledOption>
                <StyledOption value="3">3</StyledOption>
                <StyledOption value="2">2</StyledOption>
                <StyledOption value="1">1</StyledOption>
                
              </StyledSelect>
            </StyledLabel>
            {errors.painLevel && touched.painLevel ? (
            <span className="error-message">{errors.painLevel}</span>
          ) : null}
          </StyledFormElementWrapper>
        </StyledSelectWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="howLongHadPain">
            <StyledLabelHeader
              required
              isEmpty={errors.howLongHadPain && touched.howLongHadPain}
            >
              How long have you had this pain for?</StyledLabelHeader>
            <StyledInput
              id="howLongHadPain"
              name="howLongHadPain"
              type="text"
              onChange={handleChange}
              value={values.howLongHadPain}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.howLongHadPain && touched.howLongHadPain ? (
            <span className="error-message">{errors.howLongHadPain}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="onAnyMedication">
            <StyledLabelHeader
              required
              isEmpty={errors.onAnyMedication && touched.onAnyMedication}
            >
              Are you on any medication for the pain?</StyledLabelHeader>
            <StyledInput
              id="onAnyMedication"
              name="onAnyMedication"
              type="text"
              onChange={handleChange}
              value={values.onAnyMedication}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.onAnyMedication && touched.onAnyMedication ? (
            <span className="error-message">{errors.onAnyMedication}</span>
          ) : null}
        </StyledFormElementWrapper>
        
        {/* <StyledFormElementWrapper>
          <StyledLabel htmlFor="addToNewsletter">
            <StyledInput
              id="addToNewsletter"
              name="addToNewsletter"
              type="checkbox"
              onChange={(event) => setIsNewsletterChecked(event.currentTarget.checked)}
              // checked
              // {...register('addToNewsletter',{ required: false })}
            />
            <StyledLabelHeader className="checkbox">
              Subscribe to my newsletter
            </StyledLabelHeader>
          </StyledLabel>
        </StyledFormElementWrapper> */}

<StyledFormElementWrapper>
                    <StyledLabel htmlFor="addToNewsletter">
                      <StyledInput
                        id="addToNewsletter"
                        name="addToNewsletter"
                        type="checkbox"
                        onChange={(event) => setIsNewsletterChecked(event.currentTarget.checked)}
                        // checked
                        // {...register('addToNewsletter',{ required: false })}
                      />
                      <StyledLabelHeader className="checkbox">
                        Subscribe to my newsletter
                      </StyledLabelHeader>
                    </StyledLabel>
                  </StyledFormElementWrapper>

      </StyledFieldset>
      <StyledButton
        type="submit"
        value="Send Message"
        disabled={isSubmitting || !isFormValid}
      >
        Submit
      </StyledButton>
      {messageSent && !isSuccessMessage && (
        <ErrorMessage>
          <p>Something went wrong please try again.</p>
        </ErrorMessage>
      )}
    </StyledForm>
  )
}

export default GroupHealingForm
