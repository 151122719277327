import React, { useState, useLayoutEffect } from "react"
import { GlobalStyle, StyledMain } from "../styledComponents/base"
import CookieNotice from "../components/cookieNotice"
import { useStaticQuery, graphql } from "gatsby"
import "../css/fonts.css"
import Header from "./header"
import FooterContent from "./footer"
import SignupNewsletterForm from "./formNewsletterSignup"
import AuthOverlay from "../components/AuthOverlay"
import { StyledSignUpForm } from "../styledComponents/signUpForm"
import SiteNotification from "./siteNotification"
import { StyledWhatsappButton } from "../styledComponents/button"
import Icon from "./icon"

const LayoutNoStripe = ({ isHomePage, children, displayNavBorder, hasHero, isLander, landerType, location, hideNewsletterSignUp, isOffer, noHeaderFooter }) => {
  const [isCookieSet, setIsCookieSet] = useState(true)
  const [isNewsletterCookieSet, setIsNewsletterCookieSet] = useState(true)
  const [isNotificationSet, setIsNotificationSet] = useState(false)
  const [isDiscountSet, setIsDiscountCookieSet] = useState(false)
  const [discountValue, getDiscountCookieValue] = useState(false)
  const [showWhatsAppButton, setShowWhatsAppButton] = useState(false)
  const { energyBoost } = useStaticQuery(graphql`
    query EnergyBoostQuery {
      # if there was more than one user, this would need to be filtered
      energyBoost: allWpEvent(sort: {fields: eventsMetaData___datetime, order: ASC}, filter: {eventsMetaData: {eventpassed: {ne: true}}, eventsTag: {nodes: {elemMatch: {slug: {eq: "quantum-touch-energy-boost"}}}}}, limit: 1) {
        nodes {
          title
          uri
          id
          eventsMetaData {
            price
            usPrice
            datetime
            timezone
          }
        }
      }
    }
  `)

  const energyBoostData = energyBoost
  const checkIfCookieExists = () => {
    document.cookie
      .split(";")
      .some(item => item.trim().startsWith("cookie-policy="))
      ? setIsCookieSet(true)
      : setIsCookieSet(false)
  }

  const createCookie = (value) => {
    document.cookie = value + `=true; path=/; host=${process.env.GATSBY_DOMAIN_NAME
      }; ${process.env.GATSBY_SECURE_COOKIE ? "secure;" : ""}`
    setIsCookieSet(true)
  }

  const checkIfNewsletterCookieExists = () => {
    document.cookie
      .split(";")
      .some(item => (item.trim().startsWith("newsletter=")) || (item.trim().startsWith("newsletter-closed=")))
      ? setIsNewsletterCookieSet(true)
      : setIsNewsletterCookieSet(false)
  }

  const checkDiscountCookieValue = (name) => {
    setIsDiscountCookieSet(true)
    const re = new RegExp(name + "=([^;]+)")
    const value = re.exec(document.cookie)
    const earlyBirdDiscountName = unescape(value[1])
    let earlyBirdDiscountValue
    if (earlyBirdDiscountName === 'f3Hj2ob221') {//20% off code
      earlyBirdDiscountValue = 20
    } else if (earlyBirdDiscountName === '0Ijss23Nmx1') {// 30% off code
      earlyBirdDiscountValue = 30
    }

  }

  const checkIfDiscountCookieExists = () => {
    if (document.cookie.split(";").some(item => item.trim().startsWith("eb="))) {
      checkDiscountCookieValue('eb')
    }
    else {
      setIsDiscountCookieSet(false)
    }
  }

  const checkIfDiscountParam = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const couponCode = urlParams.get('cc')
    if (couponCode) {
      createDiscountCookie(couponCode)
      checkIfDiscountCookieExists('eb')
    } else {
      checkIfDiscountCookieExists('eb')
    }
  }

  const createDiscountCookie = (value) => {
    var d = new Date();
    d.setTime(d.getTime() + (2 * 24 * 60 * 60 * 1000));
    var expires = "; expires=" + d.toGMTString();

    document.cookie = `eb=${value}; path=/; ${expires}; host=${process.env.GATSBY_DOMAIN_NAME
      }; ${process.env.GATSBY_SECURE_COOKIE ? "secure;" : ""}`
    setIsDiscountCookieSet(true)
  }

  const createNotificationCookie = (value) => {
    document.cookie = value + `=true; path=/; host=${process.env.GATSBY_DOMAIN_NAME
      }; ${process.env.GATSBY_SECURE_COOKIE ? "secure;" : ""}`
    setIsNotificationSet(true)
  }

  const checkNotificationCookieValue = (name) => {
    setIsNotificationSet(true)
    const re = new RegExp(name + "=([^;]+)")
    const value = re.exec(document.cookie)
    const NotificationCookieName = unescape(value[1])
    if (NotificationCookieName === 'true') {
      setIsNotificationSet(true)
    } else {
      setIsNotificationSet(false)
    }

  }
  const checkIfNotificationCookieExists = () => {
    if (document.cookie.split(";").some(item => item.trim().startsWith(energyBoostData.nodes[0].id))) {
      checkNotificationCookieValue(energyBoostData.nodes[0].id)
    }
    else {
      setIsNotificationSet(false)
    }
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      checkIfCookieExists()
      checkIfNewsletterCookieExists()
      checkIfDiscountParam()
      checkIfNotificationCookieExists()
      if (isHomePage) {
        setTimeout(() => setShowWhatsAppButton(true), 3000)
      } else {
        setShowWhatsAppButton(true)
      }
      return
    }
  }, [])
  
  return (
    <>
      <GlobalStyle />
      {!isCookieSet && !isLander && !noHeaderFooter && <CookieNotice isCookieSet={isCookieSet} createCookie={createCookie} />}
      <AuthOverlay />
      {process.env.GATSBY_NOTIFICATION_BANNER === 'true' && !isNotificationSet &&
        <SiteNotification isNotificationSet={isNotificationSet} createNotificationCookie={createNotificationCookie} energyBoostData={energyBoostData} />
      }
      <div className="global-wrapper" data-is-root-path={isHomePage}>
        {!isLander && !noHeaderFooter &&
          <Header displayNavBorder={displayNavBorder} hasHero={hasHero} />
        }

        <StyledMain hasHero={hasHero} isOffer={isOffer} landerType={landerType}>
          {children}
        </StyledMain>

        {!isHomePage && !isLander && !hideNewsletterSignUp && !noHeaderFooter &&
          <StyledSignUpForm>
            <SignupNewsletterForm createCookie={createCookie} />
          </StyledSignUpForm>
        }

        {!isLander && !noHeaderFooter ?
          <FooterContent isCookieSet={isCookieSet} />
          :
          <></>
        }
        {showWhatsAppButton && !isLander && !noHeaderFooter &&
          <StyledWhatsappButton isHomePage={isHomePage} target="_blank" href="https://api.whatsapp.com/send/?app_absent=0&amp;phone=447457405084"><Icon name="whatsapp" /> <span>Chat to Tracy</span></StyledWhatsappButton>
        }
      </div>
    </>
  )
}

export default LayoutNoStripe